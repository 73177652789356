import React from 'react';
import { Home, Settings, Database, Cpu, ChevronLeft, ChevronRight } from 'lucide-react';

const Sidebar = ({ isOpen, setCurrentPage, currentPage, toggleSidebar }) => {
  const NavItem = ({ page, icon: Icon, children }) => (
    <button
      onClick={() => setCurrentPage(page)}
      className={`flex items-center w-full px-6 py-2 mt-4 duration-200 ${
        currentPage === page
          ? 'bg-gray-600 bg-opacity-25 text-gray-100 border-l-4 border-gray-100'
          : 'text-gray-500 hover:bg-gray-600 hover:bg-opacity-25 hover:text-gray-100'
      }`}
    >
      <Icon className="w-5 h-5" />
      <span className={`mx-4 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
        {children}
      </span>
    </button>
  );

  return (
    <div 
      className={`
        transition-all duration-300 ease-in-out 
        bg-gray-800 relative flex flex-col
        ${isOpen ? 'w-64' : 'w-20'}
      `}
    >
      <div className="flex items-center justify-center h-14 border-b border-gray-700 overflow-hidden">
        <span className={`text-white text-2xl font-semibold transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
          Dashboard
        </span>
      </div>
      <nav className="flex-1 overflow-y-auto overflow-x-hidden py-4">
        <NavItem page="dashboard" icon={Home}>Dashboard</NavItem>
        <NavItem page="devices" icon={Cpu}>Devices</NavItem>
        <NavItem page="data" icon={Database}>Data</NavItem>
        <NavItem page="settings" icon={Settings}>Settings</NavItem>
      </nav>
      <button 
        onClick={toggleSidebar}
        className={`
          absolute top-2 bg-gray-800 text-white p-1 rounded-full shadow-lg
          transition-all duration-300 ease-in-out
          ${isOpen ? '-right-3' : '-right-3 transform translate-x-1/2'}
        `}
      >
        {isOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
      </button>
    </div>
  );
};

export default Sidebar;