import React, { useState } from 'react';
import { Thermometer, Waves } from 'lucide-react';
import SensorVisualization from './SensorVisualization';
import Sidebar from './Sidebar';
//
const generateCurrentData = () => {
  const data = [];
  let time = new Date('2024-01-25T21:00:00');
  let baseVelocity = 0.5;
  let baseDirection = 180;
  for (let i = 0; i < 100; i++) {
    const velocityNoise = (Math.random() - 0.5) * 0.2;
    const directionNoise = (Math.random() - 0.5) * 20;
    data.push({
      time: time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
      velocity: Math.max(0, Math.min(6, baseVelocity + velocityNoise)),
      direction: (baseDirection + directionNoise + 360) % 360
    });
    time.setMinutes(time.getMinutes() + 15);
    
    // Slowly change base values to simulate changing current patterns
    baseVelocity += (Math.random() - 0.5) * 0.1;
    baseDirection += (Math.random() - 0.5) * 5;
  }
  return data;
};

const sensorData = [
  {
    id: 1,
    name: 'Temperature Sensor',
    description: 'Temperature and Humidity Sensor Located in Room 1',
    icon: Thermometer,
    type: 'temperature',
    data: [
      { time: '00:00', value: 21 },
      { time: '01:00', value: 22 },
      { time: '02:00', value: 23 },
      { time: '03:00', value: 24 },
      { time: '04:00', value: 23 },
      { time: '05:00', value: 22 },
    ]
  },
  {
    id: 2,
    name: 'Current Sensor',
    description: 'Current Sensor Located in Ocean Station A',
    icon: Waves,
    type: 'current',
    data: generateCurrentData()
  },
];
//

const App = () => {
  const [currentPage, setCurrentPage] = useState('dashboard');
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  

  const PageTemplate = ({ title }) => (
    <div>
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">{title}</h2>
      <div className="bg-white rounded-lg shadow-md p-6">
        <p className="text-gray-600">This is a placeholder for the {title} page. Add your content here.</p>
      </div>
    </div>
  );

  const Dashboard = () => (
    <div>
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Dashboard</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        {sensorData.map((sensor) => (
          <div key={sensor.id} className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-lg font-semibold text-gray-700 mb-4">{sensor.name}</h3>
            <sensor.icon className="w-16 h-16 text-blue-500 mb-4" />
            <p className="text-gray-600 mb-4">{sensor.description}</p>
            <button
              onClick={() => setSelectedSensor(sensor)}
              className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              View Details
            </button>
          </div>
        ))}
      </div>
    </div>
  );

  const Content = () => {
    switch (currentPage) {
      case 'dashboard':
        return <Dashboard />;
      case 'devices':
        return <PageTemplate title="Devices" />;
      case 'data':
        return <PageTemplate title="Data" />;
      case 'settings':
        return <PageTemplate title="Settings" />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar 
        isOpen={isSidebarOpen} 
        setCurrentPage={setCurrentPage} 
        currentPage={currentPage}
        toggleSidebar={toggleSidebar}
      />
      <div className="flex-1 flex flex-col overflow-hidden transition-all duration-300 ease-in-out">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-blue-100"> {/* Changed this line */}
          <div className="container mx-auto px-6 py-8">
            <Content />
          </div>
        </main>
      </div>
      {selectedSensor && (
        <SensorVisualization
          sensor={selectedSensor}
          onClose={() => setSelectedSensor(null)}
        />
      )}
    </div>
  );
};

export default App;